<template>
  <v-card-text class="text-h5 pa-0">
    <FormSkeleton
      v-if="
        (customerId && Object.keys(customerById).length <= 0) ||
        (copiedCustomer && copyFormLoading)
      "
    />
    <v-form v-else ref="form" v-model="valid" lazy-validation :disabled="(accessRight.length < 2 && accessRight.includes('show'))">
      <Alert :alert.sync="alert" :message="message" />
      <v-row>
        <v-col cols="12">
          <v-stepper class="pb-0" v-model="e6" vertical non-linear flat>
            <v-stepper-step
              class="py-3 px-4 text-h6"
              :complete="e6 > 1"
              @click.native="accordionStepper(e6)"
              editable
              step="1"
              :rules="[
                (value) => !!model.name_1,
                (value) => !/^\s*$/.test(model.name_1),
                (value) =>
                  !model.name_1 || (model.name_1 && model.name_1.length <= 200),
                (value) => !model.email || /.+@.+\..+/.test(model.email),
                (value) =>
                  !model.email || (model.email && model.email.length <= 64),
                (value) =>
                  !model.email_invoice || /.+@.+\..+/.test(model.email_invoice),
                (value) =>
                  !model.email_invoice ||
                  (model.email_invoice && model.email_invoice.length <= 64),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.postalCode1'),
                    $t('common.less'),
                    10,
                    model.postal_code_1
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.pobox1'),
                    $t('common.less'),
                    10,
                    model.po_box_1
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.address1'),
                    $t('common.less'),
                    250,
                    model.address_1
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.town1'),
                    $t('common.less'),
                    100,
                    model.town_1
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.state'),
                    $t('common.less'),
                    200,
                    model.state
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.country'),
                    $t('common.less'),
                    200,
                    model.country
                  ),
              ]"
            >
              <v-row :dense="true">
                <v-col cols="6" :dense="true">
                  <h6 class="primary--text mt-2 font-weight-regular">
                    {{ $t("customer.basicDetails") }}
                  </h6>
                </v-col>
                <v-col cols="6" class="text-end" :dense="true">
                  <v-menu
                    bottom
                    v-if="customerId"
                    :close-on-click="true"
                    :offset-y="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="remarksList && remarksList.length > 0 ? 'success' : 'primary'"
                        class="ma-2"
                        outlined
                        small
                        @click="remarksDialog = true"
                        :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
                      >
                        {{ $t("remarks.remarks") }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="ma-2"
                        small
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          !valid ||
                          (!!model.is_locked && model.user_id !== user.id)
                        "
                      >
                        <v-icon left> mdi-menu-down </v-icon>
                        {{ $t("quotation.options") }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="accessRight.includes('create')"
                        dense
                        class="primary--text"
                        @click.stop="copyCustomer"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon small class="primary--text">
                              mdi-content-copy
                            </v-icon>
                            {{ $t("quotation.copy") }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        dense
                        class="primary--text"
                        @click.stop="actDialog = true"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon small class="primary--text">
                              mdi-pulse
                            </v-icon>
                            {{ $t("quotation.activityLog") }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        dense
                        class="primary--text"
                        @click="attachedDocumentsDialog = true"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon small class="primary--text">
                              mdi-file-document
                            </v-icon>
                            {{ $t("employee.attachedDocuments") }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item dense v-if="accessRight.includes('delete')" class="error--text" @click="confirmationDialog = true, delete_item = customerId">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon small class="error--text">
                              mdi-delete-sweep-outline
                            </v-icon>
                            {{$t('common.delete')}}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    small
                    color="primary"
                    :loading="loading"
                    :disabled="
                      !valid || (!!model.is_locked && model.user_id !== user.id) || (accessRight.length < 2 && accessRight.includes('show'))
                    "
                    @click="saveRole"
                  >
                    {{ $t("customer.save") }}
                  </v-btn>
                  <v-btn
                    v-if="this.$route.params.customer_id"
                    small
                    class="text-capitalize ms-2"
                    outlined
                    color="primary"
                    :loadingSaveAndContinue="loadingSaveAndContinue"
                    :disabled="
                      !valid || (!!model.is_locked && model.user_id !== user.id) || (accessRight.length < 2 && accessRight.includes('show'))
                    "
                    @click="saveAndContinue"
                  >
                    {{ $t("customer.saveAndContinue") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-step>

            <v-stepper-content
              step="1"
              class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
            >
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="asterisk"
                    autocomplete="new-password"
                    hide-details="auto"
                    v-model.trim="model.name_1"
                    :label="$t('customer.customerName')"
                    :rules="customerValid"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="selectedEmployee"
                    readonly
                    required
                    :label="$t('customer.field.salesRepresentative')"
                    @click="employeeDialog = true"
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.email"
                    :label="$t('customer.field.email')"
                    :rules="emailValid"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.email_invoice"
                    :label="$t('customer.field.emailInvoice')"
                    :rules="emailValid"
                    required
                  />
                  <v-select
                    hide-details="auto"
                    v-model="selectedGroup"
                    :items="groupValue"
                    item-text="description"
                    item-value="id"
                    return-object
                    :menu-props="{ bottom: true, offsetY: true }"
                    :label="$t('customer.field.group')"
                  />
                  <v-select
                    hide-details="auto"
                    v-model="selectedStatus"
                    :items="statusValue"
                    item-text="description"
                    item-value="id"
                    return-object
                    :menu-props="{ bottom: true, offsetY: true }"
                    :label="$t('customer.field.status')"
                  />
                  <v-select
                    hide-details="auto"
                    v-model="selectedPaymentTerms"
                    :items="paymentTermsValue"
                    item-text="description"
                    item-value="id"
                    return-object
                    :menu-props="{ bottom: true, offsetY: true }"
                    :label="$t('customer.field.termsOfPayment')"
                  />
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="model.customer_opening_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details="auto"
                        v-model="formattedStartDate"
                        :label="$t('customer.field.customerOpeningDate')"
                        v-bind="attrs"
                        :rules="profileById.date_format ? dateValid : dateValid2"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="model.customer_opening_date"
                      @change="
                        $refs.startDateMenu.save(model.customer_opening_date)
                      "
                    >
                      <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    hide-details="auto"
                    v-model="selectedRegion"
                    :items="regionValue"
                    item-text="description"
                    item-value="id"
                    return-object
                    :menu-props="{ bottom: true, offsetY: true }"
                    :label="$t('customer.field.area')"
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.address_1"
                    :label="$t('customer.field.address1')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.address1'),
                        $t('common.less'),
                        250,
                        model.address_1
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.postal_code_1"
                    :label="$t('customer.field.postalCode1')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.postalCode1'),
                        $t('common.less'),
                        10,
                        model.postal_code_1
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.po_box_1"
                    :label="$t('customer.field.pobox1')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.pobox1'),
                        $t('common.less'),
                        10,
                        model.po_box_1
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.town_1"
                    :label="$t('customer.field.town1')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.town1'),
                        $t('common.less'),
                        100,
                        model.town_1
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.state"
                    :label="$t('customer.field.state')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.state'),
                        $t('common.less'),
                        200,
                        model.state
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.country"
                    :label="$t('customer.field.country')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.country'),
                        $t('common.less'),
                        200,
                        model.country
                      ),
                    ]"
                    required
                  />
                  <v-menu
                    ref="endDateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="model.customer_end_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details="auto"
                        v-model="formattedEndDate"
                        :label="$t('customer.field.customerEndDate')"
                        v-bind="attrs"
                        :rules="profileById.date_format ? dateValid : dateValid2"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="
                        model.customer_opening_date
                          ? model.customer_opening_date
                          : ''
                      "
                      v-model="model.customer_end_date"
                      @change="$refs.endDateMenu.save(model.customer_end_date)"
                    >
                      <v-btn small class="primary" @click="$refs.endDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="ma-0 my-2">
                <v-col cols="12" class="text-center pa-0">
                  <v-btn
                    disabled
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                  >
                    <v-icon>mdi-chevron-double-up</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="e6 = 2"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-step
              class="py-3 px-4 text-h6"
              :complete="e6 > 2"
              @click.native="accordionStepper(e6)"
              editable
              step="2"
              :rules="[
                (value) =>
                  lessThanValidation(
                    $t('customer.field.postalCode2'),
                    $t('common.less'),
                    10,
                    model.postal_code_2
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.pobox2'),
                    $t('common.less'),
                    10,
                    model.po_box_2
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.site'),
                    $t('common.less'),
                    100,
                    model.site
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.authorizedDealerNo'),
                    $t('common.less'),
                    30,
                    model.authorized_dealer_no
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.neighborhood'),
                    $t('common.less'),
                    100,
                    model.neighborhood
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.floor'),
                    $t('common.less'),
                    5,
                    model.floor
                  ),
                (value) => !profileById.customer_bookkeeping_number || !!model.book_kiping_no,
                (value) =>
                  !model.book_kiping_no ||
                  /^[0-9]*$/.test(model.book_kiping_no),
                (value) =>
                  !model.book_kiping_no || model.book_kiping_no.length <= 15,
                (value) => !model.name_2 || !/^\s*$/.test(model.name_2),
                (value) =>
                  !model.name_2 || (model.name_2 && model.name_2.length <= 200),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.address2'),
                    $t('common.less'),
                    200,
                    model.address_2
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.town2'),
                    $t('common.less'),
                    200,
                    model.town_2
                  ),
              ]"
            >
              <h6 class="primary--text font-weight-regular">
                {{ $t("customer.otherDetails") }}
              </h6>
            </v-stepper-step>

            <v-stepper-content
              step="2"
              class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
            >
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    autocomplete="new-password"
                    hide-details="auto"
                    v-model.trim="model.name_2"
                    :label="$t('customer.customerName2')"
                    :rules="customer2Valid"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.address_2"
                    :label="$t('customer.field.address2')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.address2'),
                        $t('common.less'),
                        200,
                        model.address_2
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.postal_code_2"
                    :label="$t('customer.field.postalCode2')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.postalCode2'),
                        $t('common.less'),
                        10,
                        model.postal_code_2
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.po_box_2"
                    :label="$t('customer.field.pobox2')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.pobox2'),
                        $t('common.less'),
                        10,
                        model.po_box_2
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.town_2"
                    :label="$t('customer.field.town2')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.town2'),
                        $t('common.less'),
                        200,
                        model.town_2
                      ),
                    ]"
                    required
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details="auto"
                    v-model="model.site"
                    :label="$t('customer.field.site')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.site'),
                        $t('common.less'),
                        100,
                        model.site
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model.trim="model.authorized_dealer_no"
                    :label="$t('customer.field.authorizedDealerNo')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.authorizedDealerNo'),
                        $t('common.less'),
                        30,
                        model.authorized_dealer_no
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    class="asterisk"
                    hide-details="auto"
                    v-model.trim="model.book_kiping_no"
                    :label="$t('customer.field.bookKipingNo')"
                    :rules="bookkipingNoValid"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.neighborhood"
                    :label="$t('customer.field.neighborhood')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.neighborhood'),
                        $t('common.less'),
                        100,
                        model.neighborhood
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.floor"
                    :label="$t('customer.field.floor')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.floor'),
                        $t('common.less'),
                        5,
                        model.floor
                      ),
                    ]"
                    required
                  />
                </v-col>
              </v-row>
              <v-row class="ma-0 my-2">
                <v-col cols="12" class="text-center pa-0">
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="e6 = 1"
                  >
                    <v-icon>mdi-chevron-double-up</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="e6 = 3"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-step
              v-if="accessRight.includes('financial')"
              class="py-3 px-4 text-h6"
              :complete="e6 > 3"
              @click.native="accordionStepper(e6)"
              editable
              step="3"
            >
              <h6 class="primary--text font-weight-regular">
                {{ $t("customer.financial") }}
              </h6>
            </v-stepper-step>

            <v-stepper-content
              v-if="accessRight.includes('financial')"
              step="3"
              class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
            >
              <v-row>
                <v-col cols="12" sm="6">
                  <v-checkbox
                    hide-details="auto"
                    v-model="model.vat_charge"
                    :label="$t('customer.field.vatCharge')"
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.maximum_credit"
                    :label="$t('customer.field.maximumCredit')"
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.fixed_discount"
                    :label="$t('customer.field.fixedDiscount')"
                  />
                  <v-autocomplete
                    hide-details="auto"
                    v-model="selectedPriceListGroup"
                    :items="priceListGroupValue"
                    item-text="description"
                    item-value="id"
                    flat
                    return-object
                    :label="$t('product.priceListGroup')"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox
                    hide-details="auto"
                    v-model="model.customer_order_no_compulsory"
                    :label="$t('customer.field.customerOrderNoRequired')"
                  />
                  <v-checkbox
                    hide-details="auto"
                    v-model="model.check_credit"
                    :label="$t('customer.field.checkCredit')"
                  />
                  <v-checkbox
                    hide-details="auto"
                    v-model="model.check_payment_dates"
                    :label="$t('customer.field.checkPaymentDates')"
                  />
                  <v-checkbox
                    hide-details="auto"
                    v-model="model.restricted_customer"
                    :label="$t('customer.field.restrictedCustomer')"
                  />
                </v-col>
              </v-row>
              <h6 class="primary--text font-weight-regular mt-4">
                {{ $t("customer.obligation") }}
              </h6>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    readonly
                    hide-details="auto"
                    v-model="financial.invoices"
                    @click="openDialogBox('Invoices')"
                    :label="$t('customer.field.invoices')"
                  />
                  <v-text-field
                    readonly
                    hide-details="auto"
                    v-model="financial.futureChecks"
                    @click="openDialogBox('FutureChecks')"
                    :label="$t('customer.field.futureChecks')"
                  />
                  <v-text-field
                    readonly
                    hide-details="auto"
                    v-model="financial.proformaInvoices"
                    @click="openDialogBox('ProformaInvoices')"
                    :label="$t('customer.field.proformaInvoices')"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    readonly
                    hide-details="auto"
                    v-model="financial.deliveryNotes"
                    @click="openDialogBox('DeliveryNotes')"
                    :label="$t('customer.field.deliveryNotes')"
                  />
                  <v-text-field
                    readonly
                    hide-details="auto"
                    v-model="financial.customerOrders"
                    @click="openDialogBox('CustomerOrders')"
                    :label="$t('customer.field.customerOrders')"
                  />
                  <v-text-field
                    readonly
                    hide-details="auto"
                    v-model="financial.totalObligations"
                    :label="$t('customer.field.totalObligations')"
                  />
                </v-col>
              </v-row>
              <h6 class="primary--text font-weight-regular mt-8">
                {{ $t("customer.turnOver") }}
              </h6>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    readonly
                    hide-details="auto"
                    v-model="financial.thisYear"
                    @click="openDialogBox('CurrentYearTurnOver')"
                    :label="$t('customer.field.thisYear')"
                  />
                  <v-text-field
                    readonly
                    hide-details="auto"
                    v-model="financial.lastYear"
                    @click="openDialogBox('BeforeCurrentYearTurnOver')"
                    :label="$t('customer.field.lastYear')"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    readonly
                    hide-details="auto"
                    v-model="financial.before"
                    @click="openDialogBox('OlderYearTurnOver')"
                    :label="$t('customer.field.before')"
                  />
                  <v-text-field
                    readonly
                    hide-details="auto"
                    v-model="financial.totalTurnOver"
                    @click="openDialogBox('TotalTurnOver')"
                    :label="$t('customer.field.totalTurnOver')"
                  />
                </v-col>
              </v-row>
              <v-row class="ma-0 my-2">
                <v-col cols="12" class="text-center pa-0">
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="e6 = 2"
                  >
                    <v-icon>mdi-chevron-double-up</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="e6 = 4"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-step
              class="py-3 px-4 text-h6"
              :complete="accessRight.includes('financial') ? e6 > 4 : e6 > 3"
              @click.native="accordionStepper(e6)"
              editable
              :step="accessRight.includes('financial') ? 4 : 3"
              :rules="[
                (value) =>
                  lessThanValidation(
                    $t('customer.field.proposalNote'),
                    $t('common.less'),
                    255,
                    model.proposal_note
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.deliveryNote'),
                    $t('common.less'),
                    255,
                    model.delivery_note
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.orderNote'),
                    $t('common.less'),
                    255,
                    model.order_note
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.invoiceNote'),
                    $t('common.less'),
                    255,
                    model.invoice_note
                  ),
                (value) =>
                  lessThanValidation(
                    $t('customer.field.warningNote'),
                    $t('common.less'),
                    255,
                    model.warning_note
                  )
              ]"
            >
              <h6 class="primary--text font-weight-regular">
                {{ $t("customer.notes") }}
              </h6>
            </v-stepper-step>

            <v-stepper-content
              :step="accessRight.includes('financial') ? 4 : 3"
              class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
            >
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details="auto"
                    v-model="model.proposal_note"
                    :label="$t('customer.field.proposalNote')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.proposalNote'),
                        $t('common.less'),
                        255,
                        model.proposal_note
                      ),
                    ]"
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.delivery_note"
                    :label="$t('customer.field.deliveryNote')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.deliveryNote'),
                        $t('common.less'),
                        255,
                        model.delivery_note
                      ),
                    ]"
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.order_note"
                    :label="$t('customer.field.orderNote')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.orderNote'),
                        $t('common.less'),
                        255,
                        model.order_note
                      ),
                    ]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details="auto"
                    v-model="model.invoice_note"
                    :label="$t('customer.field.invoiceNote')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.invoiceNote'),
                        $t('common.less'),
                        255,
                        model.invoice_note
                      ),
                    ]"
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.warning_note"
                    :label="$t('customer.field.warningNote')"
                    :rules="[
                      lessThanValidation(
                        $t('customer.field.warningNote'),
                        $t('common.less'),
                        255,
                        model.warning_note
                      ),
                    ]"
                  />
                  <v-textarea
                    class="mt-4"
                    hide-details="auto"
                    dense
                    v-model="model.general_remarks"
                    :label="$t('customer.field.generalRemarks')"
                  />
                </v-col>
              </v-row>
              <v-row class="ma-0 my-2">
                <v-col cols="12" class="text-center pa-0">
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="accessRight.includes('financial') ? e6 = 3 : e6 = 2"
                  >
                    <v-icon>mdi-chevron-double-up</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="accessRight.includes('financial') ? e6 = 5 : e6 = 4"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-step
              class="py-3 px-4 text-h6"
              :complete="accessRight.includes('financial') ? e6 > 5 : e6 > 4"
              @click.native="accordionStepper(e6)"
              editable
              :step="accessRight.includes('financial') ? 5 : 4"
              :rules="[
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.extension0'),
                    $t('common.less'),
                    6,
                    model.extension_1
                  ),
                (value) => !model.phone_1 || /^[0-9]*$/.test(model.phone_1),
                (value) =>
                  !model.phone_1 ||
                  (model.phone_1 &&
                    model.phone_1.length >= 7 &&
                    model.phone_1.length <= 17),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.dialingArea0'),
                    $t('common.less'),
                    5,
                    model.area_prefix_1
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.countryPrefix0'),
                    $t('common.less'),
                    5,
                    model.country_prefix_1
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.extension0'),
                    $t('common.less'),
                    6,
                    model.extension_2
                  ),
                (value) => !model.phone_2 || /^[0-9]*$/.test(model.phone_2),
                (value) =>
                  !model.phone_2 ||
                  (model.phone_2 &&
                    model.phone_2.length >= 7 &&
                    model.phone_2.length <= 17),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.dialingArea0'),
                    $t('common.less'),
                    5,
                    model.area_prefix_2
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.countryPrefix0'),
                    $t('common.less'),
                    5,
                    model.country_prefix_2
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.extension0'),
                    $t('common.less'),
                    6,
                    model.extension_3
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.dialingArea0'),
                    $t('common.less'),
                    5,
                    model.area_prefix_3
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.countryPrefix0'),
                    $t('common.less'),
                    5,
                    model.country_prefix_3
                  ),
                (value) =>
                  !model.phone_3 ||
                  /^[0-9]*$/.test(model.phone_3) ||
                  $t('formRules.phoneNoInteger'),
                (value) =>
                  !model.phone_3 ||
                  (model.phone_3 &&
                    model.phone_3.length >= 7 &&
                    model.phone_3.length <= 17),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.dialingArea0'),
                    $t('common.less'),
                    5,
                    model.area_prefix_4
                  ),
                (value) => !model.phone_4 || /^[0-9]*$/.test(model.cellular_1),
                (value) =>
                  !model.cellular_1 ||
                  (model.cellular_1 &&
                    model.cellular_1.length >= 7 &&
                    model.cellular_1.length <= 17),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.countryPrefix0'),
                    $t('common.less'),
                    5,
                    model.country_prefix_4
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.dialingArea0'),
                    $t('common.less'),
                    5,
                    model.area_prefix_5
                  ),
                (value) => !model.phone_4 || /^[0-9]*$/.test(model.cellular_2),
                (value) =>
                  !model.cellular_2 ||
                  (model.cellular_2 &&
                    model.cellular_2.length >= 7 &&
                    model.cellular_2.length <= 17),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.countryPrefix0'),
                    $t('common.less'),
                    5,
                    model.country_prefix_5
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.countryPrefix0'),
                    $t('common.less'),
                    5,
                    model.country_prefix_6
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.dialingArea0'),
                    $t('common.less'),
                    5,
                    model.area_prefix_6
                  ),
                (value) => !model.fax || /^[0-9]*$/.test(model.fax),
                (value) =>
                  !model.fax ||
                  (model.fax &&
                    model.fax.length >= 7 &&
                    model.fax.length <= 17),
              ]"
            >
              <h6 class="primary--text font-weight-regular">
                {{ $t("customer.displayText") }}
              </h6>
            </v-stepper-step>

            <v-stepper-content
              :step="accessRight.includes('financial') ? 5 : 4"
              class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
            >
              <v-row>
                <v-col cols="12">
                  <v-simple-table dense height="340px" fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th style="width: 120px">
                            {{ $t("customer.communication") }}
                          </th>
                          <th>
                            {{ $t("customer.country") }}
                          </th>
                          <th>
                            {{ $t("customer.areaCode") }}
                          </th>
                          <th>
                            {{ $t("customer.no") }}
                          </th>
                          <th>
                            {{ $t("customer.extension") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ $t("customer.tel") }}</td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.country_prefix_1"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.countryPrefix0'),
                                  $t('common.less'),
                                  5,
                                  model.country_prefix_1
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.area_prefix_1"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.dialingArea0'),
                                  $t('common.less'),
                                  5,
                                  model.area_prefix_1
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.phone_1"
                              :rules="phoneValid"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.extension_1"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.extension0'),
                                  $t('common.less'),
                                  6,
                                  model.extension_1
                                ),
                              ]"
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("customer.tel") }}</td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.country_prefix_2"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.countryPrefix0'),
                                  $t('common.less'),
                                  5,
                                  model.country_prefix_2
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.area_prefix_2"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.dialingArea0'),
                                  $t('common.less'),
                                  5,
                                  model.area_prefix_2
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.phone_2"
                              :rules="phoneValid"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.extension_2"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.extension0'),
                                  $t('common.less'),
                                  6,
                                  model.extension_2
                                ),
                              ]"
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("customer.tel") }}</td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.country_prefix_3"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.countryPrefix0'),
                                  $t('common.less'),
                                  5,
                                  model.country_prefix_3
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.area_prefix_3"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.dialingArea0'),
                                  $t('common.less'),
                                  5,
                                  model.area_prefix_3
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.phone_3"
                              :rules="phoneValid"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.extension_3"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.extension0'),
                                  $t('common.less'),
                                  6,
                                  model.extension_3
                                ),
                              ]"
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("customer.cel") }}</td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.country_prefix_4"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.countryPrefix0'),
                                  $t('common.less'),
                                  5,
                                  model.country_prefix_4
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.area_prefix_4"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.dialingArea0'),
                                  $t('common.less'),
                                  5,
                                  model.area_prefix_4
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.cellular_1"
                              :rules="phoneValid"
                              required
                            />
                          </td>
                          <td rowspan="3" class="text-top"></td>
                        </tr>
                        <tr>
                          <td>{{ $t("customer.cel") }}</td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.country_prefix_5"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.countryPrefix0'),
                                  $t('common.less'),
                                  5,
                                  model.country_prefix_5
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.area_prefix_5"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.dialingArea0'),
                                  $t('common.less'),
                                  5,
                                  model.area_prefix_5
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.cellular_2"
                              :rules="phoneValid"
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("customer.fax") }}</td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.country_prefix_6"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.countryPrefix0'),
                                  $t('common.less'),
                                  5,
                                  model.country_prefix_6
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.area_prefix_6"
                              :rules="[
                                lessThanValidation(
                                  $t('customer.field.dialingArea0'),
                                  $t('common.less'),
                                  5,
                                  model.area_prefix_6
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.fax"
                              :rules="faxValid"
                              required
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row class="ma-0 my-2">
                <v-col cols="12" class="text-center pa-0">
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="accessRight.includes('financial') ? e6 = 4 : e6 = 3"
                  >
                    <v-icon>mdi-chevron-double-up</v-icon>
                  </v-btn>
                  <v-btn
                    disabled
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper>
        </v-col>
        <ActivityLogTableDialog
          :actDialog.sync="actDialog"
          :docId.sync="customerId"
          docType="Customer"
        />
        <EmployeeDialog
          @onEmployeeChange="onEmployeeChange"
          :dialog.sync="employeeDialog"
        />
        <RemarksDialog
          :dialog.sync="remarksDialog"
          :docId="customerId"
          :docType="26"
        />
        <AttachedDocumentsDialog :docId="customerId" docType="Customer" :dialog.sync="attachedDocumentsDialog" />
        <confirmation-dialog :dialog.sync="confirmationDialog" :delete_item.sync="delete_item" @deleteItem="deleteItem"/>
        <open-obligation-dialog-box :openObligation.sync="openObligation">
          <template v-slot:children>
            <CustomerOrdersRecordsTable v-if="openObligation && dialogType==='CustomerOrders'" type="CustomerOrders" :openObligation.sync="openObligation" :customerName="model.name_1" :id="customerId" />
            <DeliveryNotesRecordsTable v-if="openObligation && dialogType==='DeliveryNotes'" type="DeliveryNotes" :openObligation.sync="openObligation" :customerName="model.name_1" :id="customerId" />
            <InvoiceRecordsTable v-if="openObligation && dialogType==='Invoices'" type="Invoices" :openObligation.sync="openObligation" :customerName="model.name_1" :id="customerId" />
            <ProformaInvoicesRecordsTable v-if="openObligation && dialogType==='ProformaInvoices'" type="ProformaInvoices" :openObligation.sync="openObligation" :customerName="model.name_1" :id="customerId" />
            <FutureChecksRecordsTable v-if="openObligation && dialogType==='FutureChecks'" type="FutureChecks" :openObligation.sync="openObligation" :customerName="model.name_1" :id="customerId" /> 
            <ThisYearRecordsTable v-if="openObligation && dialogType==='CurrentYearTurnOver'" type="CurrentYearTurnOver" :openObligation.sync="openObligation" :customerName="model.name_1" :id="customerId" />
            <BeforeRecordsTable v-if="openObligation && dialogType==='OlderYearTurnOver'" type="OlderYearTurnOver" :openObligation.sync="openObligation" :customerName="model.name_1" :id="customerId" /> 
            <LastYearRecordsTable v-if="openObligation && dialogType==='BeforeCurrentYearTurnOver'" type="BeforeCurrentYearTurnOver" :openObligation.sync="openObligation" :customerName="model.name_1" :id="customerId" />
            <TotalTurnOverRecordsTable v-if="openObligation && dialogType==='TotalTurnOver'" type="TotalTurnOver" :openObligation.sync="openObligation" :customerName="model.name_1" :id="customerId" /> 
          </template>
        </open-obligation-dialog-box>
      </v-row>
    </v-form>
  </v-card-text>
</template>
<script>
import { mapGetters } from "vuex";
import { getAuxiliaryZtableValueByName, changedFormData } from "@/utils";
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import ActivityLogTableDialog from '@/components/activityLog/Dialogs/ActivityLogTableDialog.vue';
import Alert from "@/components/Alert";
import EmployeeDialog from "@/components/moduleTableDialog/Dialog/EmployeeDialog";
import { formatDateDDMMYYYY, todayDate } from "../../../../utils";
import RemarksDialog from "@/components/RemarksDialog";
import OpenObligationDialogBox from "@/components/obligation/Dialogs/OpenObligationDialogBox.vue";
import CustomerOrdersRecordsTable from "@/components/obligation/Table/CustomerOrdersRecordsTable.vue";
import DeliveryNotesRecordsTable from "@/components/obligation/Table/DeliveryNotesRecordsTable.vue";
import FutureChecksRecordsTable from "@/components/obligation/Table/FutureChecksRecordsTable.vue";
import InvoiceRecordsTable from "@/components/obligation/Table/InvoiceRecordsTable.vue";
import ProformaInvoicesRecordsTable from "@/components/obligation/Table/ProformaInvoicesRecordsTable.vue";
import LastYearRecordsTable from "@/components/obligation/Table/LastYearRecordsTable.vue";
import ThisYearRecordsTable from "@/components/obligation/Table/ThisYearRecordsTable.vue";
import BeforeRecordsTable from "@/components/obligation/Table/BeforeRecordsTable.vue";
import TotalTurnOverRecordsTable from "@/components/obligation/Table/TotalTurnOverRecordsTable.vue";
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import ConfirmationDialog from '@/components/ConfirmationDialog';
import { validationMixin } from '@/mixins/validationMixin';

export default {
  props: ["cutomerName", "inputData", "loading", "loadingSaveAndContinue"],
  name: "CustomerStep",
  components: {
    FormSkeleton,
    Alert,
    EmployeeDialog,
    ActivityLogTableDialog,
    RemarksDialog,
    OpenObligationDialogBox,
    CustomerOrdersRecordsTable,
    DeliveryNotesRecordsTable,
    FutureChecksRecordsTable,
    InvoiceRecordsTable,
    ProformaInvoicesRecordsTable,
    LastYearRecordsTable,
    ThisYearRecordsTable,
    BeforeRecordsTable,
    TotalTurnOverRecordsTable,
    AttachedDocumentsDialog,
    ConfirmationDialog
  },
  mixins: [validationMixin],
  data() {
    return {
      message: "",
      alert: false,
      e6: 1,
      previousStep: 1,
      valid: false,
      model: {
        vat_charge: true,
      },
      financial: {
        invoices: "",
        futureChecks: "",
        proformaInvoices: "",
        deliveryNotes: "",
        customerOrders: "",
        totalObligations: "",
        thisYear: "",
        lastYear: "",
        before: "",
        totalTurnOver: "",
        maximum_credit: "",
        check_credit: false,
        check_payment_dates: false,
        restricted_customer: false,
        fixed_discount: "",
      },
      formattedEndDate: "",
      formattedStartDate: "",
      originModel: {},
      activePicker: null,
      startDateMenu: false,
      endDateMenu: false,
      groupValue: [],
      regionValue: [],
      statusValue: [],
      employeeValue: [],
      paymentTermsValue: [],
      selectedGroup: null,
      selectedRegion: null,
      selectedStatus: null,
      selectedPaymentTerms: null,
      selectedEmployee: null,
      employeeDialog: false,
      actDialog: false,
      copyFormLoading: true,
      remarksDialog: false,
      openObligation: false,
      attachedDocumentsDialog: false,
      dialogType: null,
      priceListGroupValue: [],
      selectedPriceListGroup: null,
      confirmationDialog: false,
      delete_item: null,
    };
  },
  async mounted() {
    if (this.$route.params.customer_id) {
      await this.$store.dispatch("customer/GetObligation", this.$route.params.customer_id).then((data) => {
        this.financial.invoices = this.numberWithCommas(data.Invoices);
        this.financial.futureChecks = this.numberWithCommas(data.FutureChecks);
        this.financial.proformaInvoices = this.numberWithCommas(data.ProformaInvoices);
        this.financial.deliveryNotes = this.numberWithCommas(data.DeliveryNotes);
        this.financial.customerOrders = this.numberWithCommas(data.CustomerOrders);
        this.financial.totalObligations = this.numberWithCommas(data.TotalObligations);
      })
      await this.$store.dispatch("customer/GetTurnOver", this.$route.params.customer_id).then((data) => {
        this.financial.thisYear = this.numberWithCommas(data.Thisyear);
        this.financial.lastYear = this.numberWithCommas(data.Lastyear);
        this.financial.before = this.numberWithCommas(data.Before);
        this.financial.totalTurnOver = this.numberWithCommas(data.TotalTurnover);
      })
    } else {
      this.financial.invoices = this.numberWithCommas(0); 
      this.financial.futureChecks = this.numberWithCommas(0); 
      this.financial.proformaInvoices = this.numberWithCommas(0); 
      this.financial.deliveryNotes = this.numberWithCommas(0); 
      this.financial.customerOrders = this.numberWithCommas(0); 
      this.financial.totalObligations = this.numberWithCommas(0);
      this.financial.thisYear = this.numberWithCommas(0);
      this.financial.lastYear = this.numberWithCommas(0);
      this.financial.before = this.numberWithCommas(0);
      this.financial.totalTurnOver = this.numberWithCommas(0);
    }
    if (this.$route.params.copiedModel) {
      this.model = this.$route.params.copiedModel;
      this.selectedGroup = this.model.group;
      this.selectedRegion = this.model.region;
      this.selectedStatus = this.model.status;
      this.selectedPaymentTerms = this.model.paymentTerms;
      this.selectedPriceListGroup = this.model.price_list_group
      this.selectedEmployee = this.model.employee?.name;
      this.copyFormLoading = false;
    } else if (this.$route.params.customer_id) {
      await this.$store.dispatch(
        "customer/GetCustomerById",
        this.$route.params.customer_id
      );
      if (!this.model.is_locked && this.$route.params.customer_id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "Customer",
          id: this.$route.params.customer_id,
          is_locked: true,
        });
      }
      this.selectedGroup = this.model.group;
      this.selectedRegion = this.model.region;
      this.selectedStatus = this.model.status;
      this.selectedPaymentTerms = this.model.paymentTerms;
      this.selectedPriceListGroup = this.model.price_list_group;
      this.selectedEmployee = this.model.employee?.name;
    }
    if (this.customerId) {
      this.$store.dispatch('remarks/GetRemarks', {where: 'document_type|'+26, where_and: 'document_id|'+this.customerId})
    }
    this.$store.dispatch("profile/GetCompanyById");
    this.employeeValue = await this.$store.dispatch("employee/GetEmployee");
    let groupTableNameValue = await getAuxiliaryZtableValueByName(2);
    if (Object.keys(groupTableNameValue).length > 0) {
      this.groupValue = groupTableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
    let regionTableNameValue = await getAuxiliaryZtableValueByName(6);
    if (Object.keys(regionTableNameValue).length > 0) {
      this.regionValue = regionTableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
    let statusTableNameValue = await getAuxiliaryZtableValueByName(5);
    if (Object.keys(statusTableNameValue).length > 0) {
      this.statusValue = statusTableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
    let paymentTermsTableNameValue = await getAuxiliaryZtableValueByName(7);
    if (Object.keys(paymentTermsTableNameValue).length > 0) {
      this.paymentTermsValue = paymentTermsTableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
    getAuxiliaryZtableValueByName(18).then((tableProductValue) => {
      if (Object.keys(tableProductValue).length > 0) {
        this.priceListGroupValue = tableProductValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.priceListGroupValue = [...this.priceListGroupValue]
      }
    });
  },
  computed: {
    customerValid() {
      return [
        (v) => !!v || this.$t("formRules.customerRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.customerRequired"),
        (v) =>
          !v ||
          (v && v.length <= 200) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("customer.customerName"),
            type: this.$t("common.less"),
            size: 200,
          }),
      ];
    },
    customer2Valid() {
      return [
        (v) => !v || !/^\s*$/.test(v) || this.$t("formRules.spaceValidValue"),
        (v) =>
          !v ||
          (v && v.length <= 200) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("customer.customerName2"),
            type: this.$t("common.less"),
            size: 200,
          }),
      ];
    },
    emailValid() {
      return [
        (v) =>
          !v || /.+@.+\..+/.test(v) || this.$t("formRules.emailValidMessage"),
        (v) =>
          !v ||
          (v && v.length <= 64) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("customer.field.email"),
            type: this.$t("common.less"),
            size: 64,
          }),
      ];
    },
    bookkipingNoValid() {
      return [
        (v) => !this.profileById.customer_bookkeeping_number || !!v || this.$t('formRules.bookkipingNoRequired'),
        (v) =>
          !v || /^[0-9]*$/.test(v) || this.$t("formRules.bookkipingNoInteger"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.bookkipingNoValidMessage"),
      ];
    },
    phoneValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.phoneNoInteger"),
        (v) =>
          !v ||
          (v && v.length >= 7 && v.length <= 17) ||
          this.$t("formRules.phoneNoValidMessage"),
      ];
    },
    faxValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.faxInteger"),
        (v) =>
          !v ||
          (v && v.length >= 7 && v.length <= 17) ||
          this.$t("formRules.faxValidMessage"),
      ];
    },
    customerId() {
      return this.$route.params.customer_id;
    },
    copiedCustomer() {
      return this.$route.params.copiedModel;
    },
    ...mapGetters({
      customerById: "customer/customerById",
      accessRight: "accessRight",
      remarksList: 'remarks/remarksList',
      profileById: "profile/profileById",
      user: "user",
    }),
  },
  watch: {
    "model.customer_opening_date"(val) {
      this.formattedStartDate = this.profileById.date_format
        ? formatDateDDMMYYYY(val)
        : val;
    },
    "model.customer_end_date"(val) {
      this.formattedEndDate = this.profileById.date_format
        ? formatDateDDMMYYYY(val)
        : val;
    },
    profileById(val) {
      this.formattedEndDate = val.date_format
        ? formatDateDDMMYYYY(this.model.customer_opening_date)
        : this.model.customer_opening_date;
      this.formattedStartDate = val.date_format
        ? formatDateDDMMYYYY(this.model.customer_end_date)
        : this.model.customer_end_date;
    },
    e6() {
      if (this.valid) {
        this.alert = false;
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    customerById() {
      if (Object.keys(this.customerById).length > 0) {
        this.model = Object.assign({}, this.model, this.customerById);
        if (this.model.customer_opening_date) {
          this.model.customer_opening_date = new Date(
            this.model.customer_opening_date
          )
            .toISOString()
            .substring(0, 10);
        }
        if (this.model.customer_end_date) {
          this.model.customer_end_date = new Date(this.model.customer_end_date)
            .toISOString()
            .substring(0, 10);
        }
        this.$emit("update:cutomerName", this.model.name_1);
        this.originModel = Object.assign({}, this.originModel, this.model);
        this.$emit("update:inputData", this.originModel);
      }
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    todayDate: todayDate,
    numberWithCommas(x, type = 'float') {
      return x ? type === 'float' ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : type === 'float' ? (0).toFixed(2) : x;
    },
    openDialogBox(type) { 
      this.dialogType = type
      this.openObligation = true
    },
    copyCustomer() {
      let copyModel = this.originModel;
      this.$router.push({
        name: "addCustomer",
        params: {
          copiedModel: copyModel,
        },
      });
    },
    onEmployeeChange(item) {
      this.selectedEmployee = item.name;
      this.model.employee_id = item.id;
    },
    accordionStepper(current) {
      if (this.previousStep == current && this.previousStep != 5) {
        this.e6 = 5;
        this.previousStep = 5;
      } else {
        this.previousStep = current;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && v.length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    saveRole() {
      if (this.$refs.form.validate()) {
        if (this.selectedGroup && Object.keys(this.selectedGroup).length > 0) {
          this.model.group_id = this.selectedGroup.id;
        }
        if (
          this.selectedRegion &&
          Object.keys(this.selectedRegion).length > 0
        ) {
          this.model.region_id = this.selectedRegion.id;
        }
        if (
          this.selectedStatus &&
          Object.keys(this.selectedStatus).length > 0
        ) {
          this.model.status_id = this.selectedStatus.id;
        }
        if (
          this.selectedPaymentTerms &&
          Object.keys(this.selectedPaymentTerms).length > 0
        ) {
          this.model.payment_terms_id = this.selectedPaymentTerms.id;
        }
        if (
          this.selectedPriceListGroup &&
          Object.keys(this.selectedPriceListGroup).length > 0
        ) {
          this.model.price_list_group = this.selectedPriceListGroup.id;
        }
        // save only update model value using changedFormData from utils
        let saveData = changedFormData(this.model, this.originModel);
        this.$emit("update:cutomerName", this.model.name_1);
        this.$emit("update:inputData", saveData);
        this.$emit("saveRole");
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    saveAndContinue() {
      if (this.$refs.form.validate()) {
        if (this.selectedGroup && Object.keys(this.selectedGroup).length > 0) {
          this.model.group_id = this.selectedGroup.id;
        }
        if (
          this.selectedRegion &&
          Object.keys(this.selectedRegion).length > 0
        ) {
          this.model.region_id = this.selectedRegion.id;
        }
        if (
          this.selectedStatus &&
          Object.keys(this.selectedStatus).length > 0
        ) {
          this.model.status_id = this.selectedStatus.id;
        }
        if (
          this.selectedPaymentTerms &&
          Object.keys(this.selectedPaymentTerms).length > 0
        ) {
          this.model.payment_terms_id = this.selectedPaymentTerms.id;
        }
        if (
          this.selectedPriceListGroup &&
          Object.keys(this.selectedPriceListGroup).length > 0
        ) {
          this.model.price_list_group = this.selectedPriceListGroup.id;
        }
        // save only update model value using changedFormData from utils
        let saveData = changedFormData(this.model, this.originModel);
        this.$emit("update:cutomerName", this.model.name_1);
        this.$emit("update:inputData", saveData);
        this.$emit("saveRole", true);
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    async deleteItem() {
      await this.$store.dispatch('customer/DeleteCustomerItemById', this.customerId)
      this.$router.push("/customer")
    },
  },
  beforeDestroy() {
    this.$refs.form.reset();
    (this.model = {}), this.$store.commit("customer/SET_CUSTOMER_BY_ID", {});
    this.$store.commit("contact/SET_CONTACT", []);
  },
};
</script>
<style scoped>
.v-stepper__header {
  height: 48px !important;
}
</style>